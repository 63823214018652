import reducer from './reducer';

import { LOAD_CHAMPIONSHIPS } from '../actions/types';

const defaultState = [];

const championships = reducer({
  [LOAD_CHAMPIONSHIPS]: (state, action) => [...action.championships]
}, defaultState);

export default championships;
