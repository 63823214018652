import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import Heading from '../Heading';
import LinkTabs, { LinkTab } from '../LinkTab';
import Newsletter from '../Newsletter'

import ContactForm from './components/Contact';
import Management from './components/Management';
import Secretariat from './components/Secretariat';

import './index.css';


class Contact extends Component {
  render() {

    return (
      <div className="Contact">
        <Heading title="Contacteer ons" />
        <LinkTabs>
          <LinkTab name="Contact" to="/contact" />
          <LinkTab name="Bestuur" to="/contact/bestuur" />
          <LinkTab name="Secretariaat" to="/contact/secretariaat" />
        </LinkTabs>

        <div className="container Contact__Container">
          <Route exact path='/contact' component={ContactForm} />
          <Route exact path='/contact/bestuur' component={Management} />
          <Route exact path='/contact/secretariaat' render={Secretariat} />
        </div>
        <Newsletter />
      </div>
    );
  }
}

export default Contact;
