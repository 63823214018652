import React from 'react';
import RcTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

const Tooltip = ({ children, title, enabled, className, ...rest }) => {
  if (!enabled) {
    return children;
  }

  return (
    <RcTooltip placement="top" trigger={['hover', 'focus']} overlay={<span>{title}</span>}>
      <span className={className}>{children}</span>
    </RcTooltip>
  );
};

Tooltip.defaultProps = {
  enabled: true,
};

export default Tooltip;
