import React, { Component } from 'react';

import Spinner from '../Spinner';
import api from '../../utils/api';

import './Bootstrap.css';

class BootstrapContainer extends Component {
  componentWillMount() {
    const { loadSlides, loadCompetitions, loadChampionships, loadCategories, loadInfo, finishInitialLoading, loadSponsors, loadTimetables, loadAnnouncements } = this.props;

    Promise.all([
      api.get('/announcements').then(({ data }) => loadAnnouncements(data)),
      api.get('/slides').then(({ data }) => loadSlides(data)),
      api.get('/competitions').then(({ data }) => loadCompetitions(data)),
      api.get('/championships').then(({ data }) => loadChampionships(data)),
      api.get('/categories').then(({ data }) => loadCategories(data)),
      api.get('/info').then(({ data }) => loadInfo(data)),
      api.get('/sponsors').then(({ data }) => loadSponsors(data)),
      api.get('/timetables').then(({ data }) => loadTimetables(data)),
    ])
      .then(finishInitialLoading);
  }

  render () {
    return (
      <div className="Bootstrap__Spinner__Wrapper">
        <Spinner />
      </div>
    )
  }
}

export default BootstrapContainer;
