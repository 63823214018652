class LanguageManager {
  constructor(languages, defaultLanguage) {
    this.languages = languages;
    this.defaultLanguage = defaultLanguage;

    this.active = defaultLanguage;

    this.listeners = [];
  }

  isValidLanguage(lang) {
    return Object.keys(this.languages).indexOf(lang) !== -1;
  }

  addEventListener(cb) {
    this.listeners.push(cb);
    cb(this.active);

    // unsubscribe
    return () => {
      this.listeners = this.listeners.filter(listener => listener !== cb);
    };
  }

  setLanguage(language) {
    if (this.isValidLanguage(language) && language !== this.language) {
      this.active = language;
      this.notifyListeners();
    }
  }

  notifyListeners() {
    this.listeners.forEach(listener => listener(this.active));
  }
}

export default LanguageManager;
