import React from 'react';
import Heading from '../Heading';
import InfoContent from './components/InfoContent';
import { Redirect } from 'react-router-dom';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import { LinkTabs, LinkTab } from '../LinkTab';

import './Info.css';

const PATH = '/info';

const Info = ({ info: categories, category, match, firstInfoPost }) => {
  if(match.params.post === undefined && category.posts.length > 1) {
    return <Redirect to={`${match.url}/${firstInfoPost.title_url}`} />
  }

  return (
    <div>
      <Heading title="Info" subtitle='Alle info over FAM, MCLB en VJMO.' />
      <LinkTabs>
        {categories.map((category, i) => (
          <LinkTab name={category.name} key={i} to={`${PATH}/${category.name_url}`} exact={false}>
            {category.name} {category.is_recent && (
              <Tooltip title="Nieuw" className="InfoTabs__Tab__Icon">
                <Icon name="star" />
              </Tooltip>
            )}
          </LinkTab>
        ))}
      </LinkTabs>
      <InfoContent category={category} path={PATH} />
    </div>
  );
}

export default Info;
