import { connect } from 'react-redux';

import Footer from './Footer';

import { showFooter } from '../../selectors';

const mapStateToProps = (state) => ({
  showFooter: showFooter(state),
});

export default connect(
  mapStateToProps,
  undefined,
)(Footer);
