import React from 'react';

import './ChampionshipBadge.css';

const ChampionshipBadge = ({ championship, category }) => {

  const style = {
    backgroundColor: category.color_plate_hexcode,
    color: category.color_number_hexcode,
    borderColor: category.color_number_hexcode,
  }

  return (
    <div className="ChampionshipBadge" style={style}>{championship.number}</div>
  );
}

export default ChampionshipBadge;
