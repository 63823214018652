import reducer from './reducer';

import { LOAD_SLIDES } from '../actions/types';

const defaultState = [];

const slides = reducer({
  [LOAD_SLIDES]: (state, action) => [...action.slides]
}, defaultState);

export default slides;
