import { combineReducers } from 'redux';
import application from './application';
import slides from './slides';
import competitions from './competitions';
import championships from './championships';
import categories from './categories';
import info from './info';
import sponsors from './sponsors';
import timetables from './timetables';
import announcements from './announcements';

const rootReducer = combineReducers({
  application,
  slides,
  competitions,
  championships,
  categories,
  info,
  sponsors,
  timetables,
  announcements
});

export default rootReducer;
