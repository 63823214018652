import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import App from './App';

import { isLoading, getFirstInfoCategory, isMenuOpen, showFooter } from '../../selectors';

const mapStateToProps = (state) => ({
  loading: isLoading(state),
  firstInfoCategory: getFirstInfoCategory(state),
  isMenuOpen: isMenuOpen(state),
  showFooter: showFooter(state),
});

export default withRouter(connect(
  mapStateToProps,
  undefined,
)(App));
