import { FINISH_INITIAL_LOADING, OPEN_MENU, CLOSE_MENU, SHOW_FOOTER, SHOW_MENU_BUTTON } from './types';

export const finishInitialLoading = () => ({
  type: FINISH_INITIAL_LOADING,
});

export const openMenu = () => ({
  type: OPEN_MENU,
});

export const closeMenu = () => ({
  type: CLOSE_MENU,
});

export const showFooter = (isVisible) => ({
  type: SHOW_FOOTER,
  isVisible,
});

export const showMenuButton = (isVisible) => ({
  type: SHOW_MENU_BUTTON,
  isVisible,
});


