import Header from './Header';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import mapActionCreatorsToProps from '../../utils/mapActionCreatorsToProps';

import { isMenuOpen, showMenuButton } from '../../selectors';
import { openMenu, closeMenu } from '../../actions';

const mapStateToProps = (state, props) => {
  return {
    isMenuOpen: isMenuOpen(state),
    showMenuButton: showMenuButton(state),
    history: props.history,
  }
}

const actionCreators = mapActionCreatorsToProps({
  openMenu,
  closeMenu,
});

export default withRouter(connect(
  mapStateToProps,
  actionCreators,
)(Header));
