import React, { Component } from 'react';

import CalendarDetail from './CalendarDetail';

class CalendarDetailContainer extends Component {
  render() {
    return (
      <CalendarDetail {...this.props} />
    )
  }
}

export default CalendarDetailContainer;
