import React, { useEffect, useRef, useState } from 'react';
import Button from './Button';

const scriptId = 'google-recaptcha';

const createScript = (id, src) => {
  const script = document.createElement('script');
  script.id = id;
  script.src = src;
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;

  document.body.appendChild(script);
};

const loadScript = onLoad => {
  if (document.getElementById(scriptId)) {
    return;
  }

  const callBackName = 'RecaptchaLoaded';
  window[callBackName] = () => {
    onLoad();
  };

  createScript(
    scriptId,
    `https://www.google.com/recaptcha/api.js?hl=nl&onload=${callBackName}&render=explicit`
  );
};

// https://developers.google.com/recaptcha/docs/invisible
// - grecaptcha.render(container, parameters, inherit): widget_id
// - grecaptcha.execute(opt_widget_id)
// - grecaptcha.reset(opt_widget_id)
// - grecaptcha.getResponse(opt_widget_id)
const InvisibleRecaptcha = ({ children, onToken, ...rest }) => {
  const containerRef = useRef(undefined);
  const [widgetId, setWidgetId] = useState(undefined);

  const createRecaptchaWidget = () => {
    setWidgetId(
      window.grecaptcha.render(containerRef.current, {
        sitekey: '6LdsxyYUAAAAABygumP2iBbgnZcE9_BF7VHWPM55',
        size: 'invisible',
        callback: token => {
          onToken(token);
        },
        isolated: true
      })
    );
  };

  useEffect(() => {
    if (containerRef && onToken && widgetId === undefined) {
      window.grecaptcha ? createRecaptchaWidget() : loadScript(createRecaptchaWidget);
    }
  }, [containerRef, onToken, widgetId]);

  return (
    <>
      <div ref={containerRef} />
      <Button {...rest} onClick={() => window.grecaptcha.execute(widgetId)}>
        {children}
      </Button>
    </>
  );
};

export default InvisibleRecaptcha;
