import React from 'react';

import Title from '../../../Title';
import './Body.css';

import Categories from '../Categories';
import Newsletter from '../../../Newsletter';
import Sponsors from '../Sponsors';

const wrapper = (content) => {
  return {
    __html: content,
  }
}

const components = {
  categories: Categories,
  newsletter: Newsletter,
  sponsors: Sponsors,
}

const Body = ({ post, category }) => {

  const ComponentToRender = post.component
    ? components[post.component]
    : undefined;

  return (
    <div className="Body">
      <Title name={`${category.name} - ${post.title}`} />
      <h2>{post.title}</h2>
      {ComponentToRender
        ? <ComponentToRender />
        : <div dangerouslySetInnerHTML={wrapper(post.content)} />
      }
    </div>
  );
}

export default Body;
