import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Icon from '../../Icon';
import Button from '../../Button';
import ChampionshipBadge from './ChampionshipBadge';

import './Calendar.css';

const monthNames = [
  'Januari',
  'Februari',
  'Maart',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Augustus',
  'September',
  'Oktober',
  'November',
  'December'
];

const dateFormatted = (stringFrom, stringTo) => {
  const from = moment(stringFrom);
  const to = moment(stringTo);

  if (from.isSame(to)) {
    return `${from.date()} ${monthNames[from.month()]}`;
  }

  const firstPart = `${from.date()} & ${to.date()}`;

  if (monthNames[from.month()] !== monthNames[to.month()]) {
    return `${firstPart} ${monthNames[from.month()]}/${monthNames[to.month()]}`;
  }

  return `${firstPart} ${monthNames[from.month()]}`;
};

const Calendar = ({ competitions }) => (
  <div className="container Calendar__Events">
    <div className="row">
      <div className="col-lg-12">
        <table className="Calendar__Events">
          <tbody>
            {competitions.map((competition, i) => (
              <tr className="Calendar__Event" key={i}>
                <td>
                  {i === 0 && (
                    <span>
                      <Icon name="caret-right" className="Calendar__Event__Icon" />
                    </span>
                  )}
                </td>
                <td>{dateFormatted(competition.from, competition.to)}</td>
                <td>
                  {competition.location}
                  {competition.special && (
                    <span>
                      <br />
                      Speciaal: {competition.special}
                    </span>
                  )}
                </td>
                <td>
                  {competition.championshipsmclb.map(({ category, championship }, i) => (
                    <ChampionshipBadge championship={championship} category={category} key={i} />
                  ))}
                </td>
                <td>
                  {competition.championshipsvjmo.map(({ category, championship }, i) => (
                    <ChampionshipBadge championship={championship} category={category} key={i} />
                  ))}
                </td>

                <td>
                  <Link to={`/kalender/${competition.id}/${competition.location}`}>
                    <Button>Meer info</Button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

export default Calendar;
