import React from 'react';
import Title from '../Title';

const PrivacyMCLB = () => {
  return (
    <div>
      <Title name="Privacy MCLB" />
      <h3>PRIVACY BELEID – MOTOR CROSS LIEFHEBBERS BOND vzw. (MCLB vzw.)</h3>
      <p>
        Wij doen er alles aan om uw privacy te waarborgen en gaan daarom zorgvuldig om met uw
        persoonsgegevens. Wij houden ons aan de toepasselijke regelgeving met onder meer de Algemene
        Verordening Gegevensbescherming
      </p>
      <ol>
        <li>
          <span>Verzameling van informatie</span>
          <p>
            Wij verzamelen informatie wanneer u zich aanmeldt voor onze nieuwsbrieven of wanneer u
            zich aanmeldt voor de aanvraag van een vergunning. De verzamelde informatie omvat uw
            naam, uw e-mail adres en, indien van toepassing voor de aanvraag van een jaarvergunning
            de volledige identiteitsgegevens uitgegeven door de overheid, persoonlijke kenmerken
            zoals geslacht, geboorteplaats, nationaliteit, telefoonnummer, rekening nummer, de
            specifieke zaken met betrekking tot de categorie / moto / verzekeringskeuze en de
            verplichte clubaansluiting
          </p>
        </li>
        <li>
          <span>Gebruik van informatie</span>
          <p>
            Alle informatie die wij van u verzamelen kan gebruikt worden voor:
            <ul>
              <li>verdere contact per e-mail of per telefoon</li>
              <li>het versturen van nieuwsbrieven</li>
              <li>het verwerken van de gegevens voor uw vergunning</li>
              <li>
                het verstrekken van info met betrekking tot het deelnemen aan de activiteiten van de
                federatie en de koepelfederatie
              </li>
              <li>
                het verplicht verwerken van de gegevens met het oog op het ter beschikking stellen
                aan de Vlaamse overheid
              </li>
            </ul>
          </p>
        </li>
        <li>
          <span>Vertrouwelijkheid van informatie – verstrekking aan derden</span>
          <p>
            Wij verkopen, verhandelen of dragen geen persoonlijke identificeerbare info over aan
            derden. Dit geldt niet voor vertrouwde derde partijen die zorgen voor de
            IT-infrastructuur en internet omgeving, de verzekeringsmaatschappij waar is polis
            persoonlijke ongevallen en BA wordt afgesloten en de Vlaamse overheid waar wij verplicht
            zijn de ledenlijst neer te leggen. Deze partijen waken over de bescherming van uw
            persoonsgegevens en gebruiken deze strikt in het kader van de administratieve
            doelstellingen.
          </p>
        </li>
        <li>
          <span>Bescherming van informatie</span>
          <p>
            De personen die namens de federatie werken met- en toegang hebben tot uw persoonlijke
            identificeerbare gegevens zijn gehouden aan strikte geheimhouding daarvan. We gebruiken
            geavanceerde firewalls en verbindingen om gevoelige online verzonden informatie te
            beschermen.
          </p>
        </li>
        <li>
          <span>Rechten en toestemming</span>
          <p>
            Van de persoonsgegevens die wij van u ontvangen hebben heft u het recht op inzage/
            correctie / verwijdering. Door het inschrijven op de nieuwsbrief, of het aanvragen van
            de vergunning gaat u akkoord met ons privacy beleid.
          </p>
        </li>
      </ol>
      <div>Maatschappelijke zetel: Rennemonde 6 - 9700 Oudenaarde</div>
      <div>Ondernemingsnummer: 0473.267.255</div>
    </div>
  );
};

export default PrivacyMCLB;
