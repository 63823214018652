import React from 'react';

import Title from '../../Title';

const Secretariat = () => {
  return (
    <>
      <div className="row Secretariat">
        <Title name="Secretariaat" />
        <div className="col-lg-12">
          <h2>Algemeen secretariaat</h2>
        </div>
        <div className="col-lg-6">
          <h3>
            Algemeen secretariaat FAM<span>vzw</span>
          </h3>
          <p>Dhr. Ronny Van Hauteghem</p>
          <p>
            E-mailadres: <a href="mailto:fam.vzw@telenet.be">fam.vzw@telenet.be</a>
            <br />
            Tel: <a href="tel:055301464">055/30 14 64</a>
          </p>
          <p>
            Rennemonde 6<br />
            9700 Oudenaarde
          </p>
          <p>
            Open: maandag en donderdag van 19u30 tot 22u00.
            <br />
            Ondernemingsnummer: 0455.029.473
          </p>
        </div>
      </div>
      <div className="row Secretariat">
        <div className="col-lg-6">
          <h3>
            Algemeen secretariaat MCLB<span>vzw</span>
          </h3>
          <p>Dhr. Ronny Van Hauteghem</p>
          <p>
            E-mailadres:{' '}
            <a href="mailto:mclb.secretariaat@telenet.be">mclb.secretariaat@telenet.be</a>
            <br />
            Tel: <a href="tel:055301464">055/30 14 64</a>
          </p>

          <p>
            Rennemonde 6<br />
            9700 Oudenaarde
          </p>
          <p>
            Open: maandag en donderdag van 19u30 tot 22u00.
            <br />
            Ondernemingsnummer: 0473.267.255
          </p>
        </div>
        <div className="col-lg-6">
          <h3>
            Algemeen secretariaat VJMO<span>vzw</span>
          </h3>
          <p>Dhr. Jürgen Van Den Houwe</p>
          <p>
            E-mailadres:{' '}
            <a href="mailto:vjmo.secretariaat@outlook.com">vjmo.secretariaat@outlook.com</a>
            <br />
            Tel: <a href="tel:054500594">054/50 05 94</a>
          </p>

          <p>
            Gasthuisstraat 40
            <br />
            1760 Roosdaal
          </p>
          <p>
            Open: maandag en donderdag van 18u30 tot 21u30.
            <br />
            Ondernemingsnummer: 0465.872.786
          </p>
        </div>
        <div className="col-lg-12">
          <h2>Piloten secretariaat</h2>
        </div>
        <div className="col-lg-6">
          <h3>Piloten secretariaat MCLB en VJMO</h3>
          <p>Dhr. Ronny Van Hauteghem</p>
          <p>
            E-mailadres:{' '}
            <a href="mailto:mclb.secretariaat@telenet.be">mclb.secretariaat@telenet.be</a>
            <br />
            Tel: <a href="tel:055301464">055/30 14 64</a>
          </p>

          <p>
            Rennemonde 6<br />
            9700 Oudenaarde
          </p>
          <p>Open: maandag en donderdag van 19u30 tot 22u00.</p>
        </div>
        <div className="col-lg-12">
          <h2>Sportcommissie secretariaat</h2>
        </div>
        <div className="col-lg-6">
          <h3>Sportcommissie secretariaat MCLB en VJMO</h3>
          <p>Dhr. Jürgen Van Den Houwe</p>
          <p>
            E-mailadres:{' '}
            <a href="mailto:mclb.sportcommissie@outlook.com">mclb.sportcommissie@outlook.com</a>
            <br />
            Tel: <a href="tel:054500594">054/50 05 94</a>
          </p>

          <p>
            Gasthuisstraat 40
            <br />
            1760 Roosdaal
          </p>
          <p>Open: maandag en donderdag van 18u30 tot 21u30.</p>
        </div>
      </div>
    </>
  );
};

export default Secretariat;
