import React from "react";
import moment from "moment";

import ChampionshipBadge from '../Calendar/components/ChampionshipBadge';
import Title from '../Title';

import "./CalendarDetail.css";

const CalendarDetail = ({ competition }) => {
  return (
    <div className="CalendarDetail">
      <Title name={`Kalender - Wedstrijd ${competition.location}`} />
      <div className="Heading CalendarDetail__Heading">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              Locatie
              <span>{competition.location}</span>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              GPS
              <span>{competition.gps}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              Wanneer
              <span>VJMO {moment(competition.from).format('D/M/Y')} - MCLB {moment(competition.to).format('D/M/Y')}</span>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              Special
              <span>{competition.special === ''
                      ? 'Geen special'
                      : competition.special
                    }
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-10">
            <h3 className="SubTitle">Meer informatie</h3>
            <div dangerouslySetInnerHTML={{__html: competition.info}} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <h3 className="SubTitle">Kampioenschappen MCLB</h3>
            {competition.mclb.map((championship, i) => (
              <div key={i} className="CalendarDetail__Championship">
                <ChampionshipBadge championship={championship} category={championship.category} />
                {championship.category.name}
              </div>
            ))}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <h3 className="SubTitle">Kampioenschappen VJMO</h3>
            {competition.vjmo.map((championship, i) => (
              <div key={i} className="CalendarDetail__Championship">
                <ChampionshipBadge championship={championship} category={championship.category} tooltip={false} />
                {championship.category.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarDetail;
