import React, { Component } from "react";
import translationManager from "../../translations";
import translate from "./translate";

export const withTranslations = WrappedComponent => class extends Component {
  constructor(...props) {
    super(...props);

    this.state = {
      lang: translationManager.active
    };
  }

  componentDidMount() {
    this.unsubscribe = translationManager.addEventListener(lang => {
      this.setState({ lang });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { lang } = this.state;

    return (
      <WrappedComponent
        language={lang}
        translate={translate(lang)}
        {...this.props}
      />
    );
  }
};

export default withTranslations;
