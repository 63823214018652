import React from 'react';

import classNames from '../../utils/classNames';
import withInput from './withInput';

export const Dropdown = withInput(({ placeholder, data, rules, className, ...rest }) => {
  return (
    <select placeholder={placeholder} className={classNames('Form__Dropdown', className)} {...rest}>
      {placeholder && (<option disabled selected value="">{placeholder}</option>)}

      {data.map((value, i) => (
        <option value={value.value ? value.value : value} key={i}>
          {value.label ? value.label : value}
        </option>
      ))}
    </select>
  )
});
