const preParseTranslations = (translations, parentKeys = []) => {
  const pre = Object.keys(translations).reduce((result, key) => {
    const value = translations[key];

    return {
      ...result,
      ...(typeof value === "object" && !Array.isArray(value)
        ? preParseTranslations(value, parentKeys.concat(key))
        : { [parentKeys.concat(key).join(".")]: value })
    };
  }, {});

  return parentKeys.length <= 0 ? { pre, translations } : pre;
};

export default preParseTranslations;
