import React from 'react';

import withTranslations from '../../utils/translations/withTranslations';
import classNames from '../../utils/classNames';
import Button from '../Button';
import PersonalDetails from './PersonalDetails';

const TypeAndLanguage = ({ translate, language, setLanguage, type, setType }) => {
  return (
    <>
      <h3>Kies uw taal / Choisissez votre langue</h3>

      <div className="flex flex-col">
        <Button
          className="w-full max-w-2xl mb-4"
          solid={language === 'nl'}
          onClick={() => setLanguage('nl')}
        >
          Nederlands
        </Button>
        <Button
          className="w-full max-w-2xl"
          solid={language === 'fr'}
          onClick={() => setLanguage('fr')}
        >
          Français
        </Button>
      </div>

      {language && (
        <>
          <div style={{ marginTop: '4rem' }}></div>
          <h3>{translate('license_type')}</h3>

          <div className="flex">
            <button
              className={classNames(
                'w-full YearLicenseType',
                type === 'jaarvergunning' && 'YearLicenseType--active'
              )}
              onClick={() => setType('jaarvergunning')}
            >
              <div className="YearLicenseType__Title">{translate('license_type_competition')}</div>
              <p>{translate('license_type_competition_info')}</p>
            </button>
            <button
              className={classNames(
                'w-full YearLicenseType',
                type === 'trainingsvergunning' && 'YearLicenseType--active'
              )}
              onClick={() => setType('trainingsvergunning')}
            >
              <div className="YearLicenseType__Title">{translate('license_type_training')}</div>
              <p style={{ color: '#B90E40' }}>{translate('license_type_training_info')}</p>
              <p style={{ fontWeight: 'bold' }}>{translate('license_type_training_info_sub')}</p>
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default withTranslations(TypeAndLanguage);
