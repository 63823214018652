import React from 'react';

import LogoSmall from './Header/logo-2.png';
import { NavLink } from 'react-router-dom';

import classNames from '../utils/classNames';

import './Menu.css';

const Menu = ({ className }) => (
  <div className={classNames('Menu', className)}>
    <ul>
      <li>
        <NavLink exact to="/" activeClassName="active">
          Home
        </NavLink>
      </li>
      <li>
        <NavLink to="/kalender" activeClassName="active">
          Kalender
        </NavLink>{' '}
        /{' '}
        <NavLink to="/uurroosters" activeClassName="active">
          Uurroosters
        </NavLink>
      </li>
      <li>
        <NavLink to="/uitslagen" activeClassName="active">
          Uitslagen
        </NavLink>
      </li>
      <li>
        <NavLink to="/info" activeClassName="active">
          Info
        </NavLink>
      </li>
      <li>
        <NavLink to="/jaarvergunning" activeClassName="active">
          Jaarvergunning
        </NavLink>
      </li>
      <li>
        <NavLink to="/contact" activeClassName="active">
          Contact
        </NavLink>
      </li>
      <li>
        <img src={LogoSmall} alt="Logo's van MCLB en VJMO" />
      </li>
    </ul>
  </div>
);

export default Menu;
