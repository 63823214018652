import Body from './Body';
import { connect } from 'react-redux';

const mapStateToProps = (state, props) => {
  const category = state.info.find((category) => category.name_url === props.match.params.category);
  const post = category.posts.find((post) => post.title_url === props.match.params.post);

  return {
    category,
    post,
  }
};

export default connect(
  mapStateToProps,
  undefined
)(Body);
