import reducer from './reducer';

import { LOAD_SPONSORS } from '../actions/types';

const defaultState = [];

const sponsors = reducer({
  [LOAD_SPONSORS]: (state, action) => [...action.sponsors]
}, defaultState);

export default sponsors;
