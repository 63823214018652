import React from 'react';
import classNames from '../utils/classNames';

import './Icon.css';

const Icon = ({ name, spaced, spin, pulse, className, ...rest }) => (
  <span
    {...rest}
    className={classNames('Icon', 'fa', `fa-${name}`, spaced && 'Icon--spaced', spin && 'fa-spin', pulse && 'fa-pulse', className)}
  />
);

Icon.defaultProps = {
  name: '',
  spaced: false,
  spin: false,
  pulse: false,
};

export default Icon;
