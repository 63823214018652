import CalendarDetailContainer from './CalendarDetailContainer';
import { connect } from 'react-redux';

import { getCompetitionWithChampionship } from '../../selectors/competitions';

const mapStateToProps = (state, props) => ({
  competition: getCompetitionWithChampionship(state, parseInt(props.match.params.id, 10)),
});


export default connect(
  mapStateToProps,
  undefined,
)(CalendarDetailContainer);
