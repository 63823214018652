import React, { Component } from 'react';

import { Form, Input } from './Form';
import api from '../utils/api';

import './Newsletter.css';
import InvisibleRecaptcha from './InvisibleRecaptcha';

class Newsletter extends Component {
  constructor(...props) {
    super(...props);

    this.state = {
      isValid: true,
      isBusy: false,
      isSuccess: false
    };
  }

  addNewsletterContact({ body }) {
    this.setState({
      isBusy: true
    });

    api
      .post('/newsletter', {
        body
      })
      .then(res => {
        this.setState({
          isBusy: false,
          isSuccess: true
        });
      })
      .catch(err => {
        this.setState({
          isBusy: false
        });
      });
  }

  onRecaptcha = token => {
    // Token is part of formData
    this.addNewsletterContact({
      body: this.form.formData()
    });
  };

  render() {
    const { isBusy, isValid, isSuccess } = this.state;

    if (isSuccess) {
      return (
        <div className="Newsletter">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2>
                  U bent nu ingeschreven en zult telkens de nieuwsbrief ontvangen.
                  <br />
                  Dank u, het MCLB-VJMO team!
                </h2>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="Newsletter">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2>Blijf op de hoogte dankzij onze nieuwsbrief</h2>
              <p>
                Schrijf je in voor de nieuwsbrief en ontvang als eerste de nieuwste informatie over
                MCLB en VJMO.
              </p>
              <Form
                className="Newsletter__Form"
                ref={form => (this.form = form)}
                onValidationChange={valid => this.setState({ isValid: valid })}
              >
                <Input placeholder="marc@example.be" name="email" rules={['required', 'email']} />
                <div className="Newsletter__Button__Wrapper">
                  <InvisibleRecaptcha
                    disabled={isBusy || !isValid}
                    transparant
                    onToken={this.onRecaptcha}
                    className="Newsletter__Button"
                  >
                    Voeg toe
                  </InvisibleRecaptcha>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Newsletter;
