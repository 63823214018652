export const getChampionshipByIdWithCategory = (state, championshipId) => {
  const championship = state.championships.find((championship) => championship.id === championshipId);

  const category = state.categories.find((category) => category.id === championship.category_id)

  return {
    ...championship,
    category,
  }
}

export const getChampionshipsForCompetitionAndFederation = (state, competition, federation) => {
  const championships = competition.championships.map((championship) => {
    return getChampionshipByIdWithCategory(state, championship.id);
  });

  return championships.filter((championship) => championship.category.federation === federation);
}

