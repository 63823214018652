import preParseTranslations from "./preParseTranslations";
import findTranslationFactory from "./findTranslation";

import translationManager from "../../translations";

const { pre, translations } = preParseTranslations(
  translationManager.languages
);

const findTranslation = findTranslationFactory(translations);

export const translate = language => (key, defaultValue = key) => {
  const full_key = `${language}.${key}`;

  // Check if we have a pre-parsed version
  if (pre.hasOwnProperty(full_key)) {
    return pre[full_key];
  }

  // Check if we can calculate the value
  const foundTranslation = findTranslation(language, key);

  // Return the foundTranslation value
  // Or the default value
  return foundTranslation !== undefined ? foundTranslation : defaultValue;
};

export default translate;
