
import React from "react";
import Button from '../components/Button';

import './Heading.css';

const Heading = ({ title, subtitle, action }) => (
  <div className="Heading">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 Heading__Container">
          <div className="Heading__Titles">
            <div className="Heading__Title">
              {title}
            </div>
            <span className="Heading__SubTitle">
              {subtitle}
            </span>
          </div>
          {action && (
            <div className="Heading__Action">
              <Button className="Heading__Action__Button">
                {action}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

Heading.defaultProps = {
  title: undefined,
  subtitle: undefined
};

export default Heading;
