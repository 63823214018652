import React, { Component } from 'react';
import moment from 'moment';

import Title from './Title';
import Heading from './Heading';

import api, { BASE_URL } from '../utils/api';

class Results extends Component {
  constructor(...props) {
    super(...props);

    this.state = {
      results: [],
    }
  }

  componentWillMount() {
    api.get('/results').then(({ data: results }) => this.setState({ results }));
  }

  render() {
    return (
      <div className="results">
        <Title name={`Uitslagen`} />
        <Heading title={`Uitslagen`} subtitle={`Door MyLaps.`} />

        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <br />
              <h3>Daguitslagen</h3>
              <p>
                De uitslagen van zowel de MCLB als VJMO kan je terugvinden via volgende link:<br />
                <a href="https://speedhive.mylaps.com/Organizations/135272" target="_blank" rel="noopener noreferrer">
                  https://speedhive.mylaps.com/Organizations/135272
                </a>
              </p>
              <h3>Kampioenschap</h3>
              <p>
                De kampioenschappen van zowel de MCLB als VJMO kan je terugvinden via volgende link: <br />
                <a href="https://speedhive.mylaps.com/Organizations/135272/Championships" target="_blank" rel="noopener noreferrer">
                  https://speedhive.mylaps.com/Organizations/135272/Championships
                </a>
              </p>
              <h3>Vrijetijdsrijders MCLB</h3>
              <ul>
              {this.state.results.map((result) => (
                <li key={result.id}>
                  <a target="_blank" href={`${BASE_URL}/results/${result.id}`}>
                    {result.location} {moment(result.to).format('D/M')}
                  </a>
                </li>
              ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Results;
