import reducer from './reducer';

import { LOAD_ANNOUNCEMENTS } from '../actions/types';

const defaultState = {};

const announcements = reducer({
  [LOAD_ANNOUNCEMENTS]: (state, action) => action.announcements
}, defaultState);

export default announcements;
