import _ from 'lodash';

export const getFirstInfoCategory = (state) => {
  return _.head(state.info);
};

export const getCategoryByNameUrl = (state, nameUrl) => {
  return state.info.find((category) => category.name_url === nameUrl);
}

export const getFirstPostForCategory = (state, name_url) => {
  const category = getCategoryByNameUrl(state, name_url);

  if(category !== undefined) {
    return _.head(category.posts);
  }
}
