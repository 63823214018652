import React, { Component } from 'react';
import classNames from '../utils/classNames';

import './Tabs.css';

export class Tabs extends Component {
  constructor (...props) {
    super(...props);

    this.state = {
      active: this.props.active,
    };
  }

  componentWillReceiveProps ({ active }) {
    if (active !== this.state.active) {
      this.setState({ active });
    }
  }

  onTabChange (index) {
    const { history, onTabChange, path, children } = this.props;

    const headingSlug = React.Children.map(children, (child) => child.props.headingSlug)[index];

    const active = path === undefined
      ? index
      : headingSlug;

    if(path !== undefined) {
      history.push(`${path}/${headingSlug}`);
    }

    onTabChange(active);
    this.setState({ active });
  }

  getActiveIndex(active) {
    const { children } = this.props;

    // Based on number
    if(this.props.path === undefined) {
      return active >= React.Children.count(children)
        ? 0
        : active;
    }

    // Base on 'slug', return index number
    const headingSlugs = React.Children.map(children, (child) => child.props.headingSlug);

    return headingSlugs.findIndex((headingSlug) => headingSlug === active);
  }

  render () {
    const { children } = this.props;
    let { active } = this.state;

    active = this.getActiveIndex(active);

    const headings = React.Children.map(children, (child) => child.props.heading);
    const activeChild = React.Children.map(children, (child) => child)[active];

    return (
      <div className='Tabs'>
        <div className='Tabs__Wrapper'>
          <ul className='Tabs__Wrapper--left'>
            {headings.map((child, i) => (
              <li
                key={i}
                className={classNames('Tabs__Tab', i === active && 'Tabs__Tab--active')}
                onClick={() => this.onTabChange(i)}
              >
                {child}
              </li>
            ))}
          </ul>
        </div>

        <div>{activeChild.props.children}</div>
      </div>
    );
  }
}

Tabs.displayName = 'Tabs';

Tabs.defaultProps = {
  active: 0,
  path: undefined,
  onTabChange: () => {},
};

export const Tab = ({ children }) => children;

Tab.displayName = 'Tab';

export default Tabs;
