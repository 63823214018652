import React from 'react';

import classNames from '../utils/classNames';

import './Button.css';

const Button = ({ children, active, solid, small, block, transparant, className, ...rest }) => (
  <button
    {...rest}
    className={classNames('Button', active && 'Button--active', solid && 'Button--solid', small && 'Button--small', block && 'Button--block', transparant && 'Button--transparant', className)}
  >
    {children}
  </button>
);

Button.defaultProps = {
  active: false,
  solid: false,
  small: false,
  block: false,
  transparant: false,
};

export default Button;
