import React from 'react';
import Heading from '../Heading';
import LinkTabs, { LinkTab } from '../LinkTab';
import Newsletter from '../Newsletter';
import { Route, Redirect } from 'react-router-dom';
import PrivacyMCLB from './PrivacyMCLB';
import Title from '../Title';

import './Privacy.css';
import PrivacyVJMO from './PrivacyVJMO';
import PrivacyFAM from './PrivacyFAM';

const Privacy = () => {
  return (
    <div>
      <Title name="Privacy" />
      <Heading title="Privacy" />
      <LinkTabs>
        <LinkTab name="FAM" to="/privacy/fam" />
        <LinkTab name="MCLB" to="/privacy/mclb" />
        <LinkTab name="VJMO" to="/privacy/vjmo" />
      </LinkTabs>

      <div className="container PrivacyContainer">
        <Redirect from="/privacy" to="/privacy/mclb" />
        <Route exact path="/privacy/fam" component={PrivacyFAM} />
        <Route exact path="/privacy/mclb" component={PrivacyMCLB} />
        <Route exact path="/privacy/vjmo" component={PrivacyVJMO} />
      </div>
    </div>
  );
};

export default Privacy;
