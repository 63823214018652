export default translations => (language, key) => {
  try {
    const keys = key.split(".");

    if (keys.length <= 0) {
      return false;
    }

    let trans = translations[language];

    for (var i in keys) {
      trans = trans[keys[i]];
    }

    return trans;
  } catch (err) {
    return undefined;
  }
};
