import React from "react";
import classNames from "../../utils/classNames";

import Slider from "react-slick";
import Icon from "../Icon";

import "./Home.css";

const NavButton = ({ className, onClick, direction }) => {
  return (
    <button
      className={classNames(className, "Home__NavButton")}
      onClick={onClick}
    >
      <Icon name={`angle-${direction}`} className="Home__NavButton__Icon" />
    </button>
  );
};

const settings = {
  infinite: true,
  speed: 1500,
  autoplay: true,
  autoplaySpeed: 5500,
  arrows: true,
  nextArrow: <NavButton direction="right" />,
  prevArrow: <NavButton direction="left" />,
  className: "Home__Slider"
};

const Home = ({ announcement, slides }) => (
  <div className="Home">
    {slides.length > 0 && (
      <Slider {...settings}>
        {slides.map(({ url, id }) => (
          <div className="Home__Slide" key={id}>
            {<img src={url} className="Home__Image" alt="" />}

            <div className="Home__Content">
              <div className="Announcement">
                <div className="Announcement__Title">
                  <div>{announcement.text}</div>
                </div>
                <div className="Announcement__SubTitle">
                  <div>{announcement.subtext}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    )}
  </div>
);

export default Home;
