import _ from 'lodash';
import reducer from './reducer';

import { FINISH_INITIAL_LOADING, OPEN_MENU, CLOSE_MENU, SHOW_FOOTER, SHOW_MENU_BUTTON } from '../actions/types';

const defaultState = {
  loading: true,
  menuOpen: false,
  showFooter: true,
  showMenuButton: true,
};

const application = reducer({
  [FINISH_INITIAL_LOADING]: (state, action) => _.merge({}, state, {
    loading: false,
  }),
  [OPEN_MENU]: (state, action) => _.merge({}, state, {
    menuOpen: true,
  }),
  [CLOSE_MENU]: (state, action) => _.merge({}, state, {
    menuOpen: false,
  }),
  [SHOW_FOOTER]: (state, action) => _.merge({}, state, {
    showFooter: action.isVisible,
  }),
  [SHOW_MENU_BUTTON]: (state, action) => _.merge({}, state, {
    showMenuButton: action.isVisible,
  }),

}, defaultState);

export default application;
