import React from 'react';
import { Route } from 'react-router-dom';
import _ from 'lodash';
import Menu from './Menu';

import Body from './Body/index';
import DumbBody from './Body/Body';

const InfoContent = ({ category, path}) => {
  const posts = category.posts
  const needsMenu = posts && posts.length > 1;

  return (
    <div className="container Info__Content">
      <div className="row">
        {needsMenu && (
          <Menu category={category} path={path} />
        )}
        <div className={needsMenu ? 'col-lg-9 col-md-9 col-sm-9' : 'col-lg-12'}>
          {needsMenu
            ? <Route path={`${path}/:category/:post`} component={Body}/>
            : <DumbBody post={_.head(posts)} category={category}/>
          }
        </div>
      </div>
    </div>
  );
}

export default InfoContent;
