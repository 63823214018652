import Home from './Home';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  slides: state.slides,
  announcement: state.announcements
});

export default connect(
  mapStateToProps,
  undefined,
)(Home);
