/* global document */
import { Component } from 'react';

export const DEFAULT_TITLE = 'FAM-MCLB-VJMO motorcross';

class Title extends Component {
  componentDidMount() {
    this.setTitle(this.props);
  }

  componentWillReceiveProps(newProps) {
    this.setTitle(newProps);
  }

  componentWillUnmount() {
    this.setTitle({
      name: undefined,
    });
  }

  setTitle({ name }) {
    document.title = name !== undefined ? `${name} - ${DEFAULT_TITLE}` : DEFAULT_TITLE;
  }

  render() {
    return null;
  }
}

export default Title;
