import reducer from './reducer';

import { LOAD_COMPETITIONS } from '../actions/types';

const defaultState = [];

const competitions = reducer({
  [LOAD_COMPETITIONS]: (state, action) => [...action.competitions]
}, defaultState);

export default competitions;
