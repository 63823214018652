import Calendar from './Calendar';
import { connect } from 'react-redux';

import { getCompetitionsGrouped } from '../../selectors';

const mapStateToProps = state => {
  return {
    competitions: getCompetitionsGrouped(state)
  };
};

export default connect(
  mapStateToProps,
  undefined
)(Calendar);
