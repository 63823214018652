const VALID_URL_RULE = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

export const rule = (url = '') => {
  if (`${url}`.trim() === '') {
    return true;
  }

  return VALID_URL_RULE.test(url);
};

export const message = 'This field is not a valid url.';
