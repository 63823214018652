import React from 'react';

import './Footer.css';
import { Link } from 'react-router-dom';

const curYear = new Date().getFullYear();

const Footer = ({ showFooter }) => {
  if (!showFooter) {
    return null;
  }

  return (
    <div className="Footer">
      &copy; {curYear} FAM - MCLB - VJMO
      <ul>
        <li>
          <Link to="/privacy">Privacybeleid</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </div>
  );
};
export default Footer;
