import React from 'react';
import moment from 'moment';

import './Timetable.css';

const specialSeries = ['pechtraining', 'pauze', 'prijsuitreiking', 'pauzeOfSpecials'];

let previousSerie;

const setTitle = serie => {
  if (serie === previousSerie || serie.startsWith('specials')) {
    return null;
  }

  previousSerie = serie;

  return <h3>{serie}</h3>;
};

const setExtraRounds = rounds => {
  return rounds === undefined || rounds === 0 ? null : `+ ${rounds}R`;
};

const setSpecialTitle = ({ serie, from: fromObject, to: toObject, extra_rounds }) => {
  previousSerie = serie;

  const from = moment(fromObject.date);
  const to = moment(toObject.date);

  if (serie === 'pechtraining') {
    return (
      <div className="Timetable__Row">
        <div className="Timetable__Time">{from.format('HH:mm')}</div>
        <div className="Timetable__Time">
          {to.diff(from, 'minutes')}' {setExtraRounds(extra_rounds)}
        </div>
        <div className="Timetable__Categories">
          <div className="Timetable__Categories__Category">Pechtraining</div>
        </div>
      </div>
    );
  }

  if (serie === 'pauzeOfSpecials') {
    return <h3 className="Timetable__SpecialTitle">Pauze of specials</h3>;
  }

  return <h3 className="Timetable__SpecialTitle">{serie}</h3>;
};

const setRow = (time, categories) => {
  const from = moment(time.from.date);
  const to = moment(time.to.date);

  return (
    <div className="Timetable__Row">
      <div className="Timetable__Time">{from.format('HH:mm')}</div>
      <div className="Timetable__Time">
        {to.diff(from, 'minutes')}' {setExtraRounds(time.extra_rounds)}
      </div>
      <div className="Timetable__Categories">
        <div className="Timetable__Categories__Category">
          {time.categories
            .map(({ id }) => categories.find(category => category.id === id).name)
            .join(' // ')}
          {time.serie.startsWith('specials') && time.serie}
        </div>
      </div>
    </div>
  );
};

const Timetable = ({ data, categories }) => (
  <div className="Timetable">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          {data.times.map(time => (
            <div key={time.id}>
              {specialSeries.includes(time.serie) ? (
                setSpecialTitle(time)
              ) : (
                <div>
                  {setTitle(time.serie)}
                  {setRow(time, categories)}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default Timetable;
