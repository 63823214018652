import Info from './Info';
import { connect } from 'react-redux';

import { getFirstPostForCategory } from '../../selectors';

const mapStateToProps = (state, props) => ({
  match: props.match,
  info: state.info,
  category: state.info.find((category) => category.name_url === props.match.params.category),
  firstInfoPost: getFirstPostForCategory(state, props.match.params.category),
});

export default connect(
  mapStateToProps,
  undefined
)(Info);
