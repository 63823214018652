import React, { Component } from "react";
import Title from "../Title";
import Heading from "../Heading";
import Errors from "../Errors";
import Newsletter from "../Newsletter";


import api from "../../utils/api";

class Unsubscribe extends Component {
  constructor(...props) {
    super(...props);

    this.state = {
      errors: undefined,
      isSuccess: false,
    }
  }

  componentDidMount() {
    api
      .post("/unsubscribe", { body: {token: this.props.match.params.token }})
      .then(res => {
        this.setState({
          isSuccess: true,
          errors: undefined
        });
      })
      .catch(({ data: errors }) => {
        this.setState({
          errors
        });
      });
  }

  render() {
    const { errors, isSuccess } = this.state;
    return (
      <div className="Unsubscribe">
        <Title name="Uitschrijven" />
        <Heading title={"Uitschrijven"} />
        <div className="container Uitschrijven__Content">
          <div className="col-lg-12">
            {isSuccess && (
              <div>
                <h2>Uitschrijven gelukt!</h2>
                <p>
                  U bent succesvol uitgeschreven uit de nieuwsbrief.
                </p>
              </div>
            )}
            <Errors errors={errors} />
          </div>
        </div>
        <Newsletter />
      </div>
    );
  }
}

export default Unsubscribe;
