import { getChampionshipsForCompetitionAndFederation } from './championships';

export const getCompetition = (state, competitionId) => {
  return state.competitions.find((competition) => competition.id === competitionId);
};

export const getCompetitionWithChampionship = (state, competitionId) => {
  const competition = state.competitions.find((competition) => competition.id === competitionId);

  return {
    ...competition,
    mclb: getChampionshipsForCompetitionAndFederation(state, competition, 'mclb'),
    vjmo: getChampionshipsForCompetitionAndFederation(state, competition, 'vjmo'),
  }
}

export const getCompetitionPerFederation = (state, federation, championships) => {
  return state.competitions.map((competition) => {
    const championships = state.championships.filter((championship) => championship.competition_id === competition.id);

    competition[`championships${federation}`] = championships.map((championship) => {
      const category = state.categories.find((category) => category.id === championship.category_id);

      if(category.federation !== federation) {
        return undefined;
      }

      return {
        championship,
        category,
      }
    }).filter((championship) => championship !== undefined);

    return competition;
  });
}

export const getCompetitionsGrouped = (state) => {
  let competitions = getCompetitionPerFederation(state, 'mclb');

  competitions = getCompetitionPerFederation(state, 'vjmo');

  return competitions;
}
