import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import './polyfills';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import configureStore from './store';

import './index.css';
import './grid.css';

import App from './components/App';

const Root = () => {
  return (
    <Provider store={configureStore()}>
      <Router>
        <App />
      </Router>
    </Provider>
  );
};

render(<Root />, document.getElementById('root'));
