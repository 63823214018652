import React from 'react';

import CalendarView from './components/Calendar';
import { Tabs, Tab } from '../Tabs';
import Title from '../Title';
import Heading from '../Heading';

import './Calendar.css';
import { CALENDAR_YEAR } from '../Jaarvergunning';

const Calendar = ({ competitions }) => (
  <div className="calendar">
    <Title name={`Kalender`} />
    <Heading
      title={`${CALENDAR_YEAR} KALENDER`}
      subtitle={`Eerste dag VJMO, volgende dag MCLB.`}
      action={
        <a href={`webcal://${process.env.REACT_APP_DOMAIN_URL}/api/calendar.ics`}>
          Voeg toe aan mijn kalender - iCal
        </a>
      }
    />
    <CalendarView competitions={competitions} />
  </div>
);

export default Calendar;
