import reducer from './reducer';

import { LOAD_TIMETABLES } from '../actions/types';

const defaultState = {}

const timetables = reducer({
  [LOAD_TIMETABLES]: (state, action) => ({...action.timetables})
}, defaultState);

export default timetables;
