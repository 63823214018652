import React from 'react';

import Title from '../../Title';

import './Management.css';

const Management = () => {
  return (
    <>
      <Title name="Bestuur" />
      <div className="row Management">
        <div className="col-lg-6 col-md-6">
          <h3>Raad van bestuur FAM</h3>
          <h4>Voorzitter</h4>
          <p>Frans Lunenburg</p>
          <p>
            Tel: <a href="tel:0475597684">0475/59 76 84</a>
          </p>
          <h4>Secretaris</h4>
          <p>Ronny Van Hauteghem</p>
          <p>
            E-mailadres: <a href="fam.vzw@telenet.be">fam.vzw@telenet.be</a>
            <br />
            Tel: <a href="tel:055301464">055/30 14 64</a>
          </p>
          <h4>Penningmeester</h4>
          <p>Jürgen Van Den Houwe</p>
          <p>
            E-mailadres:{' '}
            <a href="mailto:vjmo.secretariaat@outlook.com">vjmo.secretariaat@outlook.com</a>
            <br />
            Tel: <a href="tel:054500594">054/50 05 94</a>
          </p>
        </div>
      </div>
      <div className="row Management">
        <div className="col-lg-6 col-md-6">
          <h3>Raad van bestuur MCLB</h3>
          <h4>Voorzitter</h4>
          <p>Willy Aper</p>
          <p>
            Tel: <a href="tel:0476221927">0476/22 19 27</a>
          </p>
          <h4>Secretaris</h4>
          <p>Ronny Van Hauteghem</p>
          <p>
            E-mailadres: <a href="mclb.secretariaat@telenet.be">mclb.secretariaat@telenet.be</a>
            <br />
            Tel: <a href="tel:055301464">055/30 14 64</a>
          </p>
          <h4>Penningmeester</h4>
          <p>Gino Coghe</p>
          <p>
            E-mailadres: <a href="info@fotocoghe.be">info@fotocoghe.be</a>
            <br />
            Tel: <a href="tel:0495506402">0495/50 64 02</a>
          </p>
        </div>
        <div className="col-lg-6 col-md-6">
          <h3>Raad van bestuur VJMO</h3>
          <h4>Voorzitter</h4>
          <p>Frans Lunenburg</p>
          <p>
            Tel: <a href="tel:0475597684">0475/59 76 84</a>
          </p>
          <h4>Secretaris / Penningmeester</h4>
          <p>Jürgen Van Den Houwe</p>
          <p>
            E-mailadres:{' '}
            <a href="mailto:vjmo.secretariaat@outlook.com">vjmo.secretariaat@outlook.com</a>
            <br />
            Tel: <a href="tel:054500594">054/50 05 94</a>
          </p>
          <h4>Beheerder</h4>
          <p>Ronny Van Hauteghem</p>
          <p>
            E-mailadres:{' '}
            <a href="mailto:mclb.secretariaat@telenet.be">mclb.secretariaat@telenet.be</a>
            <br />
            Tel: <a href="tel:055301464">055/30 14 64</a>
          </p>
        </div>
      </div>
      <div className="row Management">
        <div className="col-lg-6 col-md-6">
          <h3>Sportcommissie</h3>
          <h4>Voorzitter</h4>
          <p>Raphaël Sierens</p>
          <h4>Secretaris</h4>
          <p>Jürgen Van Den Houwe</p>
          <h4>Lid - Délégué des pilotes francophones</h4>
          <p>Danny Van Parys</p>
          <h4>Lid</h4>
          <p>Gerdy Persyn</p>
          <h4>Lid</h4>
          <p>Gerard Bauters</p>
        </div>
        <div className="col-lg-6 col-md-6">
          <h3>Pers</h3>
          <p>Guido Allewaert</p>
          <p>
            E-mailadres: <a href="mailto:guido.allewaert@skynet.be">guido.allewaert@skynet.be</a>
            <br />
            Tel: <a href="tel:051306147">051/30 61 47</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Management;
