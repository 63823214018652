import Sponsors from './Sponsors';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    sponsors: state.sponsors,
  }
};

export default connect(
  mapStateToProps,
  undefined
)(Sponsors);
