import * as required from './required';
import * as email from './email';
import * as url from './url';
import * as birthday from './birthday';

const rules = {
  required,
  email,
  url,
  birthday,
};

export default rules;
