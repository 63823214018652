import reducer from './reducer';

import { LOAD_INFO } from '../actions/types';

const defaultState = [];

const info = reducer({
  [LOAD_INFO]: (state, action) => [...action.info]
}, defaultState);

export default info;
