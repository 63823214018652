import React from 'react';

import classNames from '../../utils/classNames';
import withInput from './withInput';

export const Textarea = withInput(({ text, className, rules, ...rest }) => {
  return (
    <textarea className={classNames('Form__Textarea', className)} {...rest}></textarea>
  )
});
