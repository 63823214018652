import BootstrapContainer from './BootstrapContainer';
import { connect } from 'react-redux';
import mapActionCreatorsToProps from '../../utils/mapActionCreatorsToProps';

import {
  loadSlides,
  loadCompetitions,
  loadChampionships,
  loadCategories,
  loadInfo,
  loadSponsors,
  loadTimetables,
  loadAnnouncements,
  finishInitialLoading,
} from '../../actions';

const actionCreators = mapActionCreatorsToProps({
  loadSlides,
  loadCompetitions,
  loadChampionships,
  loadCategories,
  loadInfo,
  loadSponsors,
  loadTimetables,
  loadAnnouncements,
  finishInitialLoading,
});

export default connect(
  undefined,
  actionCreators
)(BootstrapContainer);
