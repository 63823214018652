import TimetableContainer from './TimetableContainer';
import { connect } from 'react-redux';

import { getCompetitionsGrouped } from '../../selectors';

const mapStateToProps = state => {
  return {
    timetables: state.timetables,
    categories: state.categories
  };
};

export default connect(
  mapStateToProps,
  undefined
)(TimetableContainer);
