import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import Button from "./Button";

class Recaptcha extends Component {
  render() {
    const { children, callback, ...rest } = this.props;

    return (
      <div>
        <ReCAPTCHA
          ref="recaptcha"
          sitekey="6LdsxyYUAAAAABygumP2iBbgnZcE9_BF7VHWPM55"
          onChange={callback}
          style={{ marginTop: 16 }}
        />

        <Button {...rest} style={{ marginTop: 16 }}>{children}</Button>
      </div>
    );
  }
}

Recaptcha.defaultProps = {
  callback: () => {}
};

export default Recaptcha;
