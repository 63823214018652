const VALID_BIRTHDAY_RULE = /^\d{2}[/]\d{2}[/]\d{4}$/;

export const rule = (birthday = '') => {
  if (`${birthday}`.trim() === '') {
    return true;
  }

  return VALID_BIRTHDAY_RULE.test(birthday);
};

export const message = 'This field is not a valid birthday.';
