import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import classNames from '../../utils/classNames';

import Footer from '../Footer';
import Header from '../Header';
import ToTop from '../ToTop';
import Home from '../Home';
import Calendar from '../Calendar';
import Timetable from '../Timetable';
import CalendarDetail from '../CalendarDetail';
import Results from '../Results';
import Info from '../Info';
import Jaarvergunning from '../Jaarvergunning';
import Contact from '../Contact';
import Unsubscribe from '../Unsubscribe';

import Bootstrap from '../Bootstrap';

import './App.css';
import Privacy from '../Privacy';

class App extends Component {
  render() {
    const { loading, firstInfoCategory, isMenuOpen, showFooter } = this.props;

    if (loading) {
      return <Bootstrap />;
    }

    return (
      <div className={classNames('App', isMenuOpen && 'App--openMenu')}>
        <div className="wrapper">
          <Header />

          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/privacy" component={Privacy} />

            <Route exact path="/kalender" component={Calendar} />
            <Route exact path="/kalender/:id" component={CalendarDetail} />
            <Route exact path="/kalender/:id/:slug" component={CalendarDetail} />

            <Route exact path="/uurroosters" component={Timetable} />

            <Route exact path="/uitslagen" component={Results} />

            <Route
              exact
              path="/info"
              render={() => <Redirect to={`/info/${firstInfoCategory.name_url}`} />}
            />
            <Route exact path="/info/:category" component={Info} />
            <Route exact path="/info/:category/:post" component={Info} />

            <Route exact path="/jaarvergunning" component={Jaarvergunning} />
            <Route path="/contact" component={Contact} />
            <Route path="/uitschrijven/:token" component={Unsubscribe} />
          </Switch>

          {showFooter && <div className="push" />}
        </div>
        <Footer />
        <ToTop />
      </div>
    );
  }
}

export default App;
