import React from 'react';
import { Form, Label, Input, Dropdown, Checkbox } from '../Form';
import Recaptcha from '../Recaptcha';
import withTranslations from '../../utils/translations/withTranslations';

const PersonalDetails = ({
  translate,
  requestYearLicense,
  setFederation,
  setState,
  termsLink,
  onRecaptcha,
  federations,
  isBusy,
  isValid
}) => {
  return (
    <div>
      <h3>{translate('personal_information')}</h3>
      <Form
        onValidationChange={valid => setState({ isValid: valid })}
        onSubmit={data => requestYearLicense(data)}
      >
        <div className="row">
          <div className="col-lg-6">
            <Label text={translate('first_name')} />
            <Input placeholder="Marc" name="first_name" rules={['required']} />

            <Label text={translate('last_name')} />
            <Input placeholder="Peeters" name="last_name" rules={['required']} />
          </div>
          <div className="col-lg-6">
            <Label text={translate('email')} />
            <Input placeholder="marc@peeters.be" name="email" rules={['required', 'email']} />
          </div>
          <div className="col-lg-6">
            <Label text={translate('birthday')} />
            <Input placeholder="DD/MM/YYYY" name="birthday" rules={['required', 'birthday']} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <Label text={translate('address_street')} />
            <Input
              placeholder={translate('address_street_placeholder')}
              name="address_street"
              rules={['required']}
            />
          </div>
          <div className="col-lg-6">
            <Label text={translate('address_house_number')} />
            <Input
              placeholder={translate('address_house_number_placeholder')}
              name="address_house_number"
              rules={['required']}
            />
          </div>
          <div className="col-lg-6">
            <Label text={translate('address_postal_code')} />
            <Input placeholder="0000" name="address_postal_code" rules={['required']} />
          </div>
          <div className="col-lg-6">
            <Label text={translate('address_city')} />
            <Input
              placeholder={translate('address_city_placeholder')}
              name="address_city"
              rules={['required']}
            />
          </div>
          <div className="col-lg-6">
            <Label text={translate('address_country')} />
            <Dropdown data={translate('countries')} name="address_country" rules={['required']} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <Label text={translate('federation')} />
            <Dropdown
              placeholder={translate('federation_placeholder')}
              data={federations}
              name="federation"
              rules={['required']}
              onChange={event => setFederation(event)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <Checkbox value={true} name="terms" rules={['required']} />
            <a className="Jaarvergunning__Terms__Label" target="_blank" href={termsLink}>
              {translate('terms.label')}
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <Recaptcha solid disabled={isBusy || !isValid} callback={token => onRecaptcha(token)}>
              {translate('submit')}
            </Recaptcha>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default withTranslations(PersonalDetails);
