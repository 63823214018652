import React from 'react';
import Tooltip from '../../../Tooltip';
import Icon from '../../../Icon';

import './Categories.css';

const formatAge = (from, to) => {
  if(!from && !to) {
    return '';
  }

  if (!from) {
    return `tot ${to} jaar`;
  }
  if (!to) {
    return `vanaf ${from} jaar`;
  }
  return `${from} tot ${to} jaar`;
};

const takt = (from, to) => {
  if (!from && to !== null) {
    return (
      <div>
        <Icon name="caret-left" /> {to}
      </div>
    );
  }
  if (!to && from !== null) {
    return (
      <div>
        <Icon name="caret-right" /> {from}
      </div>
    );
  }

  if (to === null || from === null) {
    return '-';
  }

  return (
    <div>
      {from}
      <Icon name="caret-right" /> {to}
    </div>
  );
};

const ColorSquare = ({ color, name }) => {
  if (name === 'Vrij') {
    return <span>Vrij</span>;
  }

  const borderColor = name && name.toLowerCase() === 'wit' ? 'black' : color;
  return (
    <div
      className="ColorSquare"
      style={{ backgroundColor: color, borderColor: borderColor }}
      title={name}
    />
  );
};

const federations = ['MCLB', 'VJMO'];

export const Component = ({ categoriesMclb, categoriesVjmo }) => (
  <div className="Categories">
    {federations.map(federation => (
      <div key={federation}>
        <h3>{federation}</h3>
        <table className="Categories__Table">
          <tbody>
            <tr>
              <th>Categorie</th>
              <th>Leeftijd</th>
              <th>Nummers</th>
              <th>Bord</th>
              <th>Cijf.</th>
              <th>2-takt (cc)</th>
              <th>4-takt (cc)</th>
            </tr>
            {(federation === 'MCLB' ? categoriesMclb : categoriesVjmo).map(
              (
                {
                  name,
                  age_from,
                  age_to,
                  numbers_from,
                  numbers_to,
                  color_number_hexcode,
                  color_plate_hexcode,
                  color_number,
                  color_plate,
                  '2_takt_from': fromTakt2,
                  '4_takt_from': fromTakt4,
                  '2_takt_to': toTakt2,
                  '4_takt_to': toTakt4
                },
                i
              ) => (
                <tr key={i}>
                  <td>{name}</td>
                  <td>{formatAge(age_from, age_to)}</td>
                  <td>
                    {numbers_from} - {numbers_to}
                  </td>
                  <td>
                    <Tooltip title={color_plate}>
                      <ColorSquare color={color_plate_hexcode} name={color_plate} />
                    </Tooltip>
                  </td>
                  <td>
                    <Tooltip title={color_number}>
                      <ColorSquare color={color_number_hexcode} name={color_number} />
                    </Tooltip>
                  </td>
                  <td>{takt(fromTakt2, toTakt2)}</td>
                  <td>{takt(fromTakt4, toTakt4)}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    ))}
  </div>
);

export default Component;
