import reducer from './reducer';

import { LOAD_CATEGORIES } from '../actions/types';

const defaultState = [];

const categories = reducer({
  [LOAD_CATEGORIES]: (state, action) => [...action.categories]
}, defaultState);

export default categories;
