import React, { Component } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import Icon from './Icon';

import './ToTop.css';

const OFFSET = 350;

class ToTop extends Component {
  constructor(...props) {
    super(...props);

    this.state = {
      isVisible: false,
    }
  }

  componentWillMount() {
    window.addEventListener('scroll', () => this.handleScroll());
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => this.handleScroll());
  }

  handleScroll() {
    const top = window.pageYOffset || document.documentElement.scrollTop;

    this.setState({
      isVisible: top > OFFSET
    });
  }

  toTop() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  render() {
    const { isVisible } = this.state;

    return (
      <ReactCSSTransitionGroup
        transitionName="ToTop"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
      >
        {isVisible && (
          <div
            className="ToTop"
            onClick={() => this.toTop()}
            key={1}
          >
            <Icon name="angle-up" />
          </div>
        )}
      </ReactCSSTransitionGroup>
    );
  }
}

export default ToTop;
