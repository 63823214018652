import React, { Component } from 'react';

import { Form, Label, Input, Dropdown, Textarea } from '../../Form';
import Recaptcha from '../../Recaptcha';
import Errors from '../../Errors';
import Title from '../../Title';
import classNames from '../../../utils/classNames';
import api from '../../../utils/api';

//import Map, { GoogleApiWrapper } from 'google-maps-react'
//import Marker from 'google-maps-react/dist/components/Marker';

import './Contact.css';

const federations = ['FAM', 'MCLB', 'VJMO'];

class Contact extends Component {
  constructor(...props) {
    super(...props);

    this.state = {
      isValid: true,
      isBusy: false,
      isSuccess: false,
      formContainerHeight: null,
      mclbSecretariaat: undefined,
      vjmoSecretariaat: undefined,
      token: undefined,
      errors: undefined,
    };
  }

  sendContactForm({ body }) {
    this.setState({
      isBusy: true,
    });

    api
      .post('/contact', {
        body,
      })
      .then((res) => {
        this.setState({
          isBusy: false,
          isSuccess: true,
          errors: undefined,
        });
      })
      .catch(({ data: errors }) => {
        console.log(errors);
        this.setState({
          isBusy: false,
          errors,
        });
      });
  }

  onRecaptcha(token) {
    this.setState({ token });
  }

  calcHeightForMap() {
    if (this.formContainer) {
      return this.formContainer.offsetHeight;
    }
  }

  scrollTo(element) {
    this[element].scrollIntoViewIfNeeded();

    this.setState({
      [element]: 'Contact__Secretariaat--highlight',
    });
  }

  //getCenteredLocation() {
  //  console.log(this.map.map.center.lat(), this.map.map.center.lng());
  //}

  render() {
    const { isBusy, isValid, isSuccess, mclbSecretariaat, vjmoSecretariaat, errors } = this.state;

    return (
      <div className="container">
        <Title name="Contact" />
        <div className="row Contact__Content">
          <div
            className="col-lg-6 col-md-6"
            ref={(formContainer) => (this.formContainer = formContainer)}
          >
            <Errors errors={errors} />
            {!isSuccess && (
              <Form
                className="Contact__Form"
                ref={(form) => (this.form = form)}
                onValidationChange={(valid) => this.setState({ isValid: valid })}
                onSubmit={(data) => this.sendContactForm(data)}
              >
                <Label text="Volledige naam" />
                <Input placeholder="Marc Peeters" name="name" rules={['required']} />
                <Label text="E-mailadres" />
                <Input placeholder="marc@peeters.be" name="email" rules={['required', 'email']} />
                <Label text="Federatie" />
                <Dropdown
                  placeholder="Kies uw federatie"
                  data={federations}
                  name="federation"
                  rules={['required']}
                />
                <Label text="Bericht" />
                <Textarea name="message" rules={['required']} />
                <Recaptcha
                  disabled={isBusy || !isValid}
                  solid
                  callback={(token) => this.onRecaptcha(token)}
                  className="Contact__Button"
                >
                  Verzenden
                </Recaptcha>
              </Form>
            )}
            {isSuccess && <p>Contactformulier is succesvol verzonden!</p>}
          </div>
          <div className="col-lg-6 col-md-6">
            {/*this.props.loaded && (
                <Map
                  zoomControl={false}
                  google={this.props.google}
                  className="Contact__Map"
                  zoom={9}
                  initialCenter={{ lat: 50.98989104168581, lng: 3.527834421875 }}
                  style={{height: this.calcHeightForMap()}}
                  //ref={(map) => this.map = map}
                >
                  <Marker
                    name={'Algemeen secretariaat MCLBvzw'}
                    position={{ lat: 51.0829207, lng: 2.9837317 }}
                    onClick={() => this.scrollTo('mclbSecretariaat')}
                  />
                  <Marker
                    name={'Algemeen secretariaat VJMOvzw'}
                    position={{ lat: 50.845130, lng: 4.079474 }}
                    onClick={() => this.scrollTo('vjmoSecretariaat')}
                  />
                </Map>
              )*/}
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;

/* export default GoogleApiWrapper({
    apiKey: 'AIzaSyAyesbQMyKVVbBgKVi2g6VX7mop2z96jBo',
    libraries: ['places', 'visualization'],
})(Contact)
 */
