import Categories from './Categories';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const categories = [...state.categories]

  return {
    categoriesMclb: categories.filter((category) => category.federation === 'mclb'),
    categoriesVjmo: categories.filter((category) => category.federation === 'vjmo'),
  }
};

export default connect(
  mapStateToProps,
  undefined
)(Categories);
