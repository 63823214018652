import React from 'react'

import './Sponsors.css';

export const Sponsors = ({ sponsors }) => {
  return(
    <div className="Sponsors">
      <div className="row">
        {sponsors.map((sponsor) => (
          <div className="col-lg-6 col-lg-6 col-md-6" key={sponsor.id}>
            <div className="Sponsors__Item">
              {sponsor.website && sponsor.website !== null
                ? <a href={sponsor.website} target="_blank"><img src={sponsor.logo_url} alt={`Logo van ${sponsor.name}`} /></a>
                : <img src={sponsor.logo_url} alt={`Logo van ${sponsor.name}`}/>
              }
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Sponsors;
