import React, { Fragment } from "react";

import "./Errors.css";

const Errors = ({ errors: data }) => {
  console.log(data);
  if (!data) {
    return null;
  }

  return (
    <div className="Errors">
      {data.errors ? (
        <Fragment>
          <div className="Errors__Title">Oops. Controleer volgende velden:</div>
          <ul className="Errors__List">
            {Object.keys(data.errors).map(error => (
              <li className="Errors__Item" key={error}>
                {data.errors[error]}
              </li>
            ))}
          </ul>
        </Fragment>
      ) : (
        <div className="Errors__Title">{data.message}</div>
      )}
    </div>
  );
};

export default Errors;
