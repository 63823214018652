import React from 'react';

import classNames from '../../utils/classNames';

import './Spinner.css';

const Spinner = ({ className }) => {
  return (
    <div className={classNames('spinner', className)}>
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
    </div>
  )
}

export default Spinner;
