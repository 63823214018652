import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import classNames from '../../utils/classNames';
import Menu from '../Menu';

import './Header.css';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-77333380-6', { debug: true });
}

class Header extends Component {
  scrollToTop() {
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    const { history, closeMenu } = this.props;

    history.listen((location, action) => {
      closeMenu();
      this.scrollToTop();

      if (process.env.NODE_ENV === 'production') {
        ReactGA.set({ page: window.location.pathname + window.location.search });
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
    });
  }

  toggleMenu(event) {
    event.preventDefault();

    const { isMenuOpen, openMenu, closeMenu } = this.props;

    this.scrollToTop();

    isMenuOpen ? closeMenu() : openMenu();
  }

  render() {
    const { isMenuOpen, showMenuButton } = this.props;

    const hamburgClassNames = classNames('hamburger', 'hamburger--spin', isMenuOpen && 'is-active');

    const menuOverlay = classNames('headerMenu', isMenuOpen && 'is-active');

    return (
      <div>
        <div className="header">
          <div className="inner">
            <div className="left">
              <Link to="/">FAM - MCLB - VJMO</Link>
            </div>
            {showMenuButton && (
              <div className="right">
                <span className="Header__Right__Text" onClick={(e) => this.toggleMenu(e)}>
                  Menu
                </span>
                <button
                  className={hamburgClassNames}
                  type="button"
                  onClick={(e) => this.toggleMenu(e)}
                >
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
        <div className={menuOverlay}>
          <Menu />
        </div>
      </div>
    );
  }
}

export default Header;
