import React from 'react';

import Timetable from './Timetable';

import { Tabs, Tab } from '../Tabs';
import Title from '../Title';
import Heading from '../Heading';

const TimetableContainer = ({ timetables, categories }) => {
  if (Object.keys(timetables).length === 0) {
    return (
      <div className="Timetable">
        <Title name={`Uurroosters`} />
        <Heading title="Uurroosters" />
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <br />
              <h3>Geen uurroosters actief.</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="Timetable">
      <Title name={`Uurroosters`} />
      <Heading title="Uurroosters" />
      <div className="Timetable__SubNav">
        <Tabs>
          {Object.keys(timetables).map(timetableName => (
            <Tab heading={timetableName} key={timetableName}>
              <Timetable data={timetables[timetableName]} categories={categories} />
            </Tab>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default TimetableContainer;
