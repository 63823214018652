import React from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

import Icon from '../../Icon';
import Tooltip from '../../Tooltip';

const Menu = ({ category, url, path }) => {
  const posts = category.posts
    .map((post) => {
      post.updated_at = moment(post.updated_at);
      return post;
    })
    .sort((b, a) => a.updated_at.diff(b.updated_at));

  return (
    <div className="col-lg-3 col-md-3 col-sm-3">
      <div className="Info__SecondNav">
        <h3 className="SubTitle Info__SecondNav__Title">Menu</h3>

        <ul className="Info__SecondNav__List">
          {posts.map((post, i) => (
            <NavLink
              to={`${path}/${category.name_url}/${post.title_url}`}
              className="Info__SecondNav__Item"
              activeClassName="Info__SecondNav__Item--active"
              key={i}
            >
              <li className="Info__SecondNav__Item">
                {post.title} {post.is_recent && (
                  <Tooltip title="Nieuw" className="Info__SecondNav__Item__Icon">
                    <Icon name="star" />
                  </Tooltip>
                )}
              </li>
            </NavLink>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Menu;
