import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

import "./LinkTab.css";

export class LinkTabs extends Component {
  render() {
    return (
      <div className="LinkTabs">
        <div className="LinkTabs__Wrapper">
          <ul className="LinkTabs__Wrapper--left">
            {this.props.children}
          </ul>
        </div>
      </div>
    );
  }
}

export const LinkTab = ({ name, to, children, exact }) => {
  return (
    <NavLink
      to={to}
      exact={exact}
      className="LinkTabs__Tab"
      activeClassName="LinkTabs__Tab--active"
    >
      <li>{children ? children : name}</li>
    </NavLink>
  )
}

LinkTab.defaultProps = {
  exact: true,
}

export default LinkTabs;
